Fastweb.Callout = (function($) {

  function doCallout(modalId) {
    $(modalId).foundation('open');
  }

  function scheduleCallout(modalId) {
    setTimeout(doCallout, 6000, modalId)
  }

  return { scheduleCallout: scheduleCallout };

})(jQuery);

