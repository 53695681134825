Fastweb.Educators = (function($) {

  init = function() {

    const accountType = $('#account_type')
    accountType.on('change', function(selected) {
      const inputs = $('#new_educator >> .cell')
      const hiddenText = $('#step-1-form')
      const register = $('#register')
      if (selected.target.value !== 'Educator' && selected.target.value !== '') {
        inputs.css('display', 'none')
        hiddenText.removeClass('is-hidden')
        register.css('padding-right', '0')
      } else {
        inputs.css('display', 'block')
        hiddenText.addClass('is-hidden')
      }
    })
    
    const passwordChange = $('#educator_password')
    passwordChange.on('change', function(password) {
      const helpText = $('#help-password')
      if (password.target.value.length < 6) {
        helpText.addClass('is-hidden')
      } else {
        helpText.removeClass('is-hidden')
      }
    })
  
}


  return { init: init };

})(jQuery);
