Fastweb.ProfileWizard = (function($) {
  updateSummaryCounts = function() {
    $.ajax(new_matches_check_url + "?matches_count=" + $('#number_awards').text());
  }

  startNewMatchesCheck = function() {
    updateSummaryCounts();
    var intervalId = setInterval(updateSummaryCounts, 3500);
    setTimeout(function() { clearInterval(intervalId); $('#updating_matches').hide(); $('#refresh_counts').show(); $('#refresh_counts a').hide(); }, 100);
  }

  triggerCountCheck = function() {
    $(".checkbox_list input[type=checkbox]").on("change", function() {
      var check_count = $(".checkbox_list input:checked").length;
      $(".counter").text(check_count);
    });
  }

  return { 
    updateSummaryCounts: updateSummaryCounts,
    startNewMatchesCheck: startNewMatchesCheck,
    triggerCountCheck: triggerCountCheck
  }

})(jQuery);

$(document).on('turbolinks:load', function() {
  Fastweb.ProfileWizard.triggerCountCheck();
});

