Fastweb.Matches = (function($) {

  init = function(current_filter) {
    $('#matches_tabs').foundation('selectTab', $('#' + current_filter), true);
  }

  goToSelectedTab = function() {
  	href = window.location.href;
    current_tab = href.split('#')[1];
    if ( current_tab ) {
      document.cookie = 'matches_tab=' + current_tab;
      $(document).ready(function(){
        $("#" + current_tab + "-label").click();
      });
    }
  }

  sortMatches = function(fetch_path, current_filter) {
    $.ajax({
      'url': fetch_path,
      'data': {"current_filter": current_filter, "order": $('#drop_sort').val(), "page": 1},
      'dataType': 'script'
    });
  }

  moreMenu = function(current_filter) {
    $(`.more_to.${current_filter}`).off('click').on('click', function () {
      $(this).siblings('.select_box').toggle();
    });
  }

  renderAd = function(targetEl, placement_name) {
    const adWrapId = $(targetEl).attr('data-ad-wrap-id');
    const isMobile = adWrapId.includes('small');

    // Check if ad has already been rendered
    if ($(targetEl).attr('data-rendered-ad') !== 'true') {
      $.get({
        url: '/awards/ajax_ad_request',
        data: { ad_wrap_id: adWrapId, is_mobile: isMobile, placement_name: placement_name },
        dataType: 'script',
      });

      // Mark the ad as rendered to prevent future requests
      $(targetEl).attr('data-rendered-ad', 'true');
    }
  }

  function renderTableForDirectories(current_filter, award_type) {
    $(`.${award_type}-row.${current_filter} .active_award`).off('click').click((e) => {
      e.stopPropagation();
    });

    $(`.${award_type}-row.${current_filter}`).off('click').click(function () {
      renderScholarshipDescription($(this));
    });
  }

  function renderScholarshipDescription(targetEl) {
    const nextRow = $(targetEl).nextAll('.description_row');
    nextRow.slideToggle(40);
    $(targetEl).toggleClass('active');
    nextRow.toggleClass('active');
  }

  function stopPropagationOnLinksWithinTableRow(current_filter, award_type) {
    const selectors = [
      '.active_award',
      '.action_wrap',
      '.has-tip',
      '.select_box a',
      '.select_box .cancel-button'
    ];
    
    selectors.forEach((selector) => {
      $(`.${award_type}-row.${current_filter} ${selector}`).click((e) => {
        e.stopPropagation();
      });
    });
  }

  matchesTableRender = function(current_filter, award_type) {
    $('.off-canvas-wrapper').addClass('add_select_icons');
    moreMenu(current_filter);

    stopPropagationOnLinksWithinTableRow(current_filter, award_type);

    $(`.${award_type}-row.${current_filter}`).off('click').click(function (e) {
      if (e.target.name !== 'status') {
        const td = $(this).children().first()[0];
        const adWrapId = $(td).attr('data-ad-wrap-id');
        const placement_name = adWrapId.includes('small') ? 'MATCHES_TABLE_DESC_MOBILE' : 'MATCHES_TABLE_DESC_DESKTOP';

        renderAd(td, placement_name);
        renderScholarshipDescription($(this));
      }
      e.stopPropagation();
      e.preventDefault();
    });
  }

  return {
    init: init,
    goToSelectedTab: goToSelectedTab,
    sortMatches: sortMatches,
    matchesTableRender: matchesTableRender,
    moreMenu: moreMenu,
    renderAd: renderAd,
    renderScholarshipDescription: renderScholarshipDescription,
    renderTableForDirectories: renderTableForDirectories
  }

})(jQuery);
