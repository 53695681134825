/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require.context('../../assets/images', true)

import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import Rails from 'rails-ujs';
import Turbolinks from 'turbolinks';

Rails.start();
Turbolinks.start();

//import { Foundation } from 'foundation-sites';
import 'foundation-sites'

import { Foundation } from 'foundation-sites/js/foundation.core';

import { Abide } from 'foundation-sites/js/foundation.abide';
Foundation.plugin(Abide, 'Abide');

Foundation.addToJquery($)

require('jquery_ui');

import '../src/javascripts/fastweb.js'
import '../src/javascripts/foundation/init_foundation.js'
import '../src/javascripts/behaviors/autocomplete.js'
import '../src/javascripts/behaviors/colleges_and_majors_autocomplete.js'
import '../src/javascripts/behaviors/profile_questions_autocomplete.js'
import '../src/javascripts/behaviors/race_checkbox_group.js'
import '../src/javascripts/behaviors/user_location.js'
import '../src/javascripts/behaviors/invite_a_friend.js'
import '../src/javascripts/behaviors/invite_a_student.js'
import '../src/javascripts/behaviors/countUp.js'
import '../src/javascripts/behaviors/color-thief.js'
import '../src/javascripts/behaviors/tracking.js'
import '../src/javascripts/behaviors/email_limits.js'
import '../src/javascripts/ads/prebid1.35.0.js'
import '../src/javascripts/ads/prebid_helper.js'
import '../src/javascripts/directories/online_learning.js'
import '../src/javascripts/registration/registration.js'
import '../src/javascripts/registration/callout.js'
import '../src/javascripts/profile_questions/profile_questions.js'
import '../src/javascripts/subscriptions/subscriptions.js'
import '../src/javascripts/awards/awards.js'
import '../src/javascripts/widgets/login_or_sign_up.js'
import '../src/javascripts/widgets/credible.js'
import '../src/javascripts/members/member.js'
import '../src/javascripts/awards/matches.js'
import '../src/javascripts/walkthrough/walkthrough.js'
import '../src/javascripts/awards/filtering.js'
import '../src/javascripts/widgets/profile_wizard_widget.js'
import '../src/javascripts/colleges/colleges.js'
import '../src/javascripts/contests/contests.js'
import '../src/javascripts/pages/landing_pages.js'
//import '../src/javascripts/cable.js'
import '../src/javascripts/jobs/jobs.js'
import '../src/javascripts/promotions/promotions.js'
import '../src/javascripts/jquery/extensions.js'
import '../src/javascripts/jquery/select2.min.js'
import '../src/javascripts/index/index.js'
// import '../src/javascripts/adblocker/blockadblock.js'
import '../src/javascripts/adblocker/blockAdBlockDetection.js'
import '../src/javascripts/lendingtree/lendingtree.js'
import '../src/javascripts/admin/admin.js'
import '../src/javascripts/admin/forms.js'
import '../src/javascripts/admin/featured_awards/featured_awards_form.js'
import '../src/javascripts/admin/promotions/promotions_form.js'
import '../src/javascripts/admin/articles/articles.js'
import '../src/javascripts/registration/educators.js'
import '../src/javascripts/admin/ampvalidator/ampvalidator.js'
import '../src/javascripts/admin/structured_data/structured_data.js'

require('./stylesheets.scss')
require('./admin.scss')
