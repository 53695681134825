
Fastweb.RaceCheck = (function($) {

  init = function(no_race_id) {
    /* Uncheck all race checkboxes when the "wish not to specify" box is checked. And uncheck the
     * "wish not to specify" box when another race is chcked
     */
    $('.race_checkbox').click(function() {
      if($(this).is(":checked") == false) {
        return;
      }

      if($(this).prop('id') == $(no_race_id).prop("id")) {
        $('.race_checkbox').not($(no_race_id)).prop("checked", false);
      } else {
        $(no_race_id).prop("checked", false);
      }
    });

  }

  return {init: init};

})(jQuery);
