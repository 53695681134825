Fastweb.Contests = (function($) {
  init = function(ctaButton) {
    $(ctaButton).on('click', function(e) {
      var visitorId = _satellite.getVisitorId().getMarketingCloudVisitorID();
      var mauuid = Fastweb.Contests.getCookieValue("__mauuid");
      
      _satellite.track('fastweb form submit');
      $('#ext_idmc').val(visitorId);
      $('#ext_id_net_results').val(mauuid);
      return true;
    });
  }

  getCookieValue = function(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') { c = c.substring(1, c.length); }
      if (c.indexOf(nameEQ) == 0) { return c.substring(nameEQ.length, c.length); }
    }
    return null;
  }

  return {init: init, getCookieValue: getCookieValue}

})(jQuery);
