Fastweb.RegistrationStep2 = (function($) {

  initMajors = function() {
    Fastweb.CollegesAndMajorsAutocomplete.init();

    $('[data-behavior=add-popular-major]').click(function() {
      Fastweb.CollegesAndMajorsAutocomplete.listAutocompleteSelect('fw_majors', {item: {value: $(this).attr('data-item-value'), label: $(this).attr('data-item-label')}}, '#users_fw_majors_filters');
      $(this).parent().remove();
    });
  }

  initColleges = function() {
    Fastweb.CollegesAndMajorsAutocomplete.init();
    Fastweb.CollegesAndMajorsAutocomplete.initUserSubmittedColleges();

    $('[data-behavior=add-popular-college]').click(function() {
      Fastweb.CollegesAndMajorsAutocomplete.listAutocompleteSelect('colleges', {item: {value: $(this).attr('data-item-value'), label: $(this).attr('data-item-label')}}, '#users_colleges_filters');
      $(this).parent().remove();
    });
  }

  initRace = function(no_race_id) {
    Fastweb.RaceCheck.init(no_race_id);
  }

  validateResidencyStatus = function(submitButton) {
    var requiredFields = $('[required=required]');
    var validForm = false;
    for(var i = 0; i < requiredFields.length; i++) {
      if(requiredFields[i].checked) {
        validForm = true;
        break;
      }
    }
    requiredFields
      .on("valid.zf.abide", function(ev,elem) {
        for(var i = 0; i < requiredFields.length; i++) {
          if(requiredFields[i].checked) {
            validForm = true;
            break;
          }
        }
        if(validForm && !$(submitButton).hasClass('validForm')) {
          $(submitButton).addClass('valid-form');
        }
      })
      
    if(validForm && !$(submitButton).hasClass('validForm')) {
      $(submitButton).addClass('valid-form');
    }
  }

  validateGpa = function(submitButton) {
    var requiredFields = $('[required=required]');
    var validForm = false;
    for(var i = 0; i < requiredFields.length; i++) {
      validForm = !requiredFields[i].value ? false : true;
    }
    $('.slider').on('moved.zf.slider', function(){
      validForm = !parseFloat($(this).children('.slider-handle').attr('aria-valuenow')) ? false : true;
      validForm ? $(submitButton).addClass('valid-form') : $(submitButton).removeClass('valid-form');
    });

    requiredFields
      .on("invalid.zf.abide", function(ev,elem) {
        if($(submitButton).hasClass('valid-form')) {
          $(submitButton).removeClass('valid-form');
        }
      })
      .on("valid.zf.abide", function(ev,elem) {
        for(var i = 0; i < requiredFields.length; i++) {
          validForm = !requiredFields[i].value ? false : true;
        }
        if(validForm && !$(submitButton).hasClass('validForm')) {
          $(submitButton).addClass('valid-form');
        }
      })

    if(validForm && !$(submitButton).hasClass('validForm')) {
      $(submitButton).addClass('valid-form');
    }
  }

  validateColleges = function(element, submitButton) {
    if($(element).children().length != 0) {
      if(!$(submitButton).hasClass('valid-form')) $(submitButton).addClass('valid-form'); 
    } else {
      if($(submitButton).hasClass('valid-form')) $(submitButton).removeClass('valid-form'); 
    }
  }

  validateMajors = function(element, submitButton) {
    if($(element).children().length != 0) {
      if(!$(submitButton).hasClass('valid-form')) $(submitButton).addClass('valid-form'); 
    } else {
      if($(submitButton).hasClass('valid-form')) $(submitButton).removeClass('valid-form'); 
    }
  }

  validateEthnicity = function(submitButton) {
    var validForm = false;
    var fieldset = $('[data-behavior=checkbox-group]');
    var ethnicityOptions = fieldset[0].elements;

    for(var i = 0; i < ethnicityOptions.length; i++) {
      if(ethnicityOptions[i].checked) {
        validForm = true;
        break;
      }
    }
    fieldset
      .on("invalid.zf.abide", function(ev,elem) {
        if($(submitButton).hasClass('valid-form')) {
          $(submitButton).removeClass('valid-form');
        }
      })
      .on("valid.zf.abide", function(ev,elem) {
        for(var i = 0; i < ethnicityOptions.length; i++) {
          if(ethnicityOptions[i].checked) {
            validForm = true;
            break;
          }
        }
        if(validForm && !$(submitButton).hasClass('validForm')) {
          $(submitButton).addClass('valid-form');
        }
      })
      
    if(validForm && !$(submitButton).hasClass('validForm')) {
      $(submitButton).addClass('valid-form');
    }
  }

  notApplicableRecords = function(selects) {
    selects.map((select) => {
      let records = $(select)
      const notApplicableValues = ['Not Applicable', 'I wish not to specify.']
      records.on('select2:select', function(e) {
        const data = e.params.data;
        // names of the items currently selected
        let names = records.select2('data').map(val => val.text)
        //checks if there are >= 1 records selected and if the current selection is 'not applicable'
        if (records.select2('data').length && notApplicableValues.includes(data.text)) {
          //update selections to just show 'not applicable'
          records.val(data.id).trigger('change')
        } else if(names.includes('Not Applicable') || names.includes('I wish not to specify.')) {
          records.val(data.id).trigger('change')
        }
      })
    })
  }

  return {
    initMajors: initMajors,
    initColleges: initColleges,
    initRace: initRace,
    validateResidencyStatus: validateResidencyStatus,
    validateGpa: validateGpa,
    validateColleges: validateColleges,
    validateMajors: validateMajors,
    validateEthnicity: validateEthnicity,
    notApplicableRecords: notApplicableRecords
  };

})(jQuery);

Fastweb.Registration = (function($){

  animateSubmitButton = function(animation, element) {
    $(animation).hide();
    $(element).click(function(){
      var buttonText = $(element).val();

      $(element).val('');
      $(animation).show();

      setTimeout(function(){
        $(element).val(buttonText);
        $(animation).hide();
      }, 1000);
    });
  }

  validateForm = function(submitButton) {
    var requiredFields = $('[required=required]');
    var validForm = false;
    for(var i = 0; i < requiredFields.length; i++) {
      validForm = !requiredFields[i].value ? false : true;
    }
    requiredFields
      .on("invalid.zf.abide", function(ev,elem) {
        if($(submitButton).hasClass('valid-form')) {
          $(submitButton).removeClass('valid-form');
        }
      })
      .on("valid.zf.abide", function(ev,elem) {
        for(var i = 0; i < requiredFields.length; i++) {
          validForm = !requiredFields[i].value ? false : true;
        }
        if(validForm && !$(submitButton).hasClass('validForm')) {
          $(submitButton).addClass('valid-form');
        }
      })

    if(validForm && !$(submitButton).hasClass('validForm')) {
      $(submitButton).addClass('valid-form');
    }
  }

  handlePasswordShow = function() {
    let passwordField = $('#user_password')
    let show = passwordField.attr('type') === 'password'
    let button = $('.password-visibility')
    if(show) {
      passwordField.attr('type', 'text')
      button.text('HIDE')
    } else {
      passwordField.attr('type', 'password')
      button.text('SHOW')
    }
  }

  return { animateSubmitButton: animateSubmitButton, validateForm: validateForm, handlePasswordShow: handlePasswordShow };
})(jQuery);
