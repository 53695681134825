
Fastweb.Admin.PromotionsForm = (function($) {

  function togglePromotionFormFields(element) {
    class_name = $(element).val();
    $("form > div").each(function(index) {
      if(!$(this).attr('class').match(/Promotion/)) {
        return;
      }
      if( $(this).hasClass(class_name) ) {
        $(this).removeClass('hide');
      } else {
        $(this).addClass('hide');
        $(this).children(":input").val("");
      }
    });
  }

  function gradeYearSelect(year) {
    return `
      <select id='select_${year}' name='promotion[matching_criteria][${year}]'>
        <option value=''></option>
        <option value=9>High School Freshman</option>
        <option value=10>High School Sophomore</option>
        <option value=11>High School Junior</option>
        <option value=12>High School Senior</option>
        <option value=13>College Freshman</option>
        <option value=14>College Sophomore</option>
        <option value=15>College Junior</option>
        <option value=16>College Senior</option>
        <option value=17>Graduate Student - Year 1</option>
        <option value=18>Graduate Student - Year 2</option>
        <option value=19>Graduate Student - Year 3</option>
        <option value=20>Graduate Student - Year 4</option>
        <option value=21>Graduate Student - Year 5</option>
      </select>
    `
  }

  matchCriteriaForm = function(criteria = {}) {
    let check_box = $('#criteria_check')
    const form = $('#match-criteria-form')
    if (check_box.prop('checked')) {
      $('#match-criteria-container').removeClass('hide')
      form.append(`
        <label>Min Age</label>
        <input type='number' step='1' name='promotion[matching_criteria][min_age]' value='${criteria.min_age ? criteria.min_age : ''}'/>
        <label>Max Age</label>
        <input type='number' step='1' name='promotion[matching_criteria][max_age]' value='${criteria.max_age ? criteria.max_age : ''}'/>
        <label>Min School Year</label>
        ${gradeYearSelect('min_school_year')}
        <label>Max School Year</label>
        ${gradeYearSelect('max_school_year')}
      `)
      $(`#select_min_school_year option[value=${criteria.min_school_year}]`).attr('selected','selected');
      $(`#select_max_school_year option[value=${criteria.max_school_year}]`).attr('selected','selected');
    } else {
      // reset radio button
      $('#fallback_radio_blank').prop('checked', true)
      $('#match-criteria-container').addClass('hide')
      form.empty()
    }
  }

  init = function(promotion_type) {
    $('#' + promotion_type + '_type').on('change', function() {
      togglePromotionFormFields(this);
    });

    if( $('#' + promotion_type + '_type').val() != "" ) {
      togglePromotionFormFields($('#' + promotion_type + '_type'));
    }
  }

  return {
    init: init,
    matchCriteriaForm: matchCriteriaForm
}

})(jQuery);
