Fastweb.EmailLimits = (function($) {
  
  init = function() {

    // ------------ TEXT AREA LIMITS -----------
  
    const emailReferrals = $("#referree_emails")
    const numberOfEmailsText = $(".help-text#referral-emails")
    const submitButton = $("#submit")
    
    //listener for key presses
    emailReferrals.on("keyup", function(e) {
      let validEmails = []
      let input = e.target.value
      submitButton.removeAttr('disabled')
      //reset helpertext color back to black
      numberOfEmailsText.css("color", "black")
        
     
      //regex to replace spaces and split at ","
      let inputArr = input.replace(/\s/g,'').split(",");
      inputArr.map(email => {
        //regex to match email address
        if (email.match(/^\s*(?:\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}\b\s*)+$/)) {
          validEmails.push(email)
        }
      })
        
      amount = validEmails.length    
        
      //max amount of emails reached
      if(amount > 50) {
        submitButton.attr('disabled', 'true')
        numberOfEmailsText.css("color", "red")
        numberOfEmailsText.text("Max emails reached!") 
      } else {
        numberOfEmailsText.text(`${amount}/50 emails added`)
      }
        
      //if textarea is empty, remove helptext, undisable button
      if(e.target.value == ""){
        submitButton.removeAttr("disabled")
        numberOfEmailsText.text("")
      }
    })
  }
    

  return {
    init: init
  };

})(jQuery);