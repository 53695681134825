Fastweb.OnlineLearning = (function($) {

  init = function() {
    $('[data-behavior=online-learning]').on('click', function(e) {
      var resourceName = e.target.innerText;
      gtag('event', 'OnlineLearningClick', {'Learning Resource': resourceName});
    });
    $('#compare-now-btn').click(function() {
      var platforms = localStorage.getItem('platforms');
      var url = $(this).data('target');
      window.location.href = url + '?q=' + platforms;
    })

    $('#remove-all-link').click(function() {
      if (!($('.platform-comparison-wrapper').hasClass('checked'))) return false;
      $('.platform-comparison-wrapper').each((index, platform) => {
        if (platform.classList.contains('checked')) {
          platform.classList.remove('checked');
          var platformChildren = platform.children[0];
          $("input[name='" + $(platformChildren).attr('alt') + "']").prop('checked', false);
          platformChildren.remove();
        }
      })
      localStorage.removeItem('platforms');
    })
  }

  platformComparisonList = function(el, photo_url) {
    var clickedPlatform = el.value;
    var storedPlatforms = localStorage.getItem('platforms');
    storedPlatforms = storedPlatforms ? storedPlatforms + ',' + clickedPlatform : clickedPlatform;
    var uniquePlatforms = Array.from(new Set(storedPlatforms.split(',')));
    storedPlatforms = uniquePlatforms.toString();

    if (uniquePlatforms.length < 5) {
      if (uniquePlatforms.length > 1) {
        $('#compare-now-btn').css({'background-color': '#ff9900', 'pointer-events': 'auto'});
      }
      localStorage.setItem("platforms", storedPlatforms);
      addPlatformsForComparison(storedPlatforms, el, photo_url);
    }
  }

  showMoreOptions = function(link_text, version) {
    let subject_ids = [];
    let cost_model = $('input[name="cost_model"]:checked').val()
    $(`input[name="subject_ids_${version}[]"]:checked`).each(function () {
      subject_ids.push($(this).val());
    });
    $.get({
      url: window.origin + '/online-learning/resources/show-all-subjects',
      dataType: 'script',
      data: {[`subject_ids_${version}`]: subject_ids, cost_model: cost_model, show_subjects: link_text, version: version}
    })
  }

  getFilteredData = function(input) {
    let version = input.attr('version')
    let subject_ids = [];
    let cost_model = $('input[name="cost_model"]:checked').val()
    $(`input[name="subject_ids_#{version}[]"]:checked`).each(function () {
      subject_ids.push($(this).val());
    });
    let action = $('form').attr('action')+'?'+$('form').serialize();
    $.get({
      url: window.origin + action,
      dataType: 'script',
      data: {[`subject_ids_${version}`]: subject_ids, cost_model: cost_model, version: version},
    })
  }

  function addPlatformsForComparison(storedPlatforms, el, photo_url) {
    $('#compare-platforms-footer').show();
    storedPlatforms.split(',').forEach(platform => {
      if ($('#' + platform)[0] && $('#' + platform)[0].checked && !($('#' + platform + '-img').length)) {
        $('.platform-comparison-wrapper').each((index, platform) => {
          if (!platform.classList.contains('checked')) {
            platform.classList.add('checked');
            if (photo_url != '') {
              platform.prepend($('<img>',{alt: el.name, id: el.id + '-img', src: photo_url})[0]);
            } else {
              platform.prepend($('<span></span>', {alt: el.name, id: el.id + '-img', text: el.name})[0]);
            }
            return false;
          }
        })
      } else if ($('#' + platform)[0] && !($('#' + platform)[0].checked)) {
        var target = $('#' + platform + '-img')[0];
        target.parentElement.classList.remove('checked');
        target.remove();
        var updatedStoredPlatforms = storedPlatforms.split(',').filter(item => item !== platform).toString();
        localStorage.setItem("platforms", updatedStoredPlatforms);
      }
      return false;
    })
  }

  $(window).on('beforeunload', function() { localStorage.removeItem('platforms') })


  document.addEventListener("DOMContentLoaded", function() {

    $('#svg-right-arrow').click(function() {
      if ($('.col_header:last-child').hasClass('show-platform')) return false;
      $('#svg-left-arrow').css({'opacity': 1, cursor: 'pointer'});
      var showPlatformHighIndex = 0;
      $('.col_header').each(function(index, el) {
        if ($(el).hasClass('show-platform')) showPlatformHighIndex = index;
      })
      var paginationText = 'Viewing ' + (showPlatformHighIndex + 1) + ' - ' + (showPlatformHighIndex + 2) + ' of ' + $('.col_header').length;
      $('#platform-pagination').text(paginationText);
      $($('.col_' + (showPlatformHighIndex-1))).removeClass('show-platform');
      $($('.col_' + (showPlatformHighIndex+1))).addClass('show-platform');

      $('.col_' + showPlatformHighIndex).find('.olr-attr-label').removeClass('invisible')
      $('.col_' + (showPlatformHighIndex-1)).find('.olr-attr-label').addClass('invisible')

      $('.field_row').each(function(index, el) {
        heightCallback = function(heights) { $(el).foundation('applyHeight', heights); }
        $(el).foundation('getHeights', heightCallback);
      })

      if ($('.col_header:last-child').hasClass('show-platform')) {
        $('#svg-right-arrow').css({'opacity': 0.5, 'cursor': 'default'});
      }
    });

    $('#svg-left-arrow').click(function(){
      if ($('.col_header:first-child').hasClass('show-platform')) return false;
      $('#svg-right-arrow').css({'opacity': 1, cursor: 'pointer'});
      var showPlatformLowIndex = 0;
      $('.col_header').each(function(index, el) {
        if ($(el).hasClass('show-platform')) {
          showPlatformLowIndex = index;
          return false;
        }
      })
      var paginationText = 'Viewing ' + (showPlatformLowIndex) + ' - ' + (showPlatformLowIndex + 1) + ' of ' + $('.col_header').length;
      $('#platform-pagination').text(paginationText);
      $($('.col_' + (showPlatformLowIndex-1))).addClass('show-platform');
      $($('.col_' + (showPlatformLowIndex+1))).removeClass('show-platform');

      $('.col_' + (showPlatformLowIndex-1)).find('.olr-attr-label').removeClass('invisible')
      $('.col_' + showPlatformLowIndex).find('.olr-attr-label').addClass('invisible')

      $('.field_row').each(function(index, el) {
        heightCallback = function(heights) { $(el).foundation('applyHeight', heights); }
        $(el).foundation('getHeights', heightCallback);
      })

      if ($('.col_header:first-child').hasClass('show-platform')) {
        $('#svg-left-arrow').css({'opacity': 0.5, 'cursor': 'default'});
      }
    })
  })

  return { init: init, platformComparisonList: platformComparisonList, showMoreOptions: showMoreOptions, getFilteredData: getFilteredData }

})(jQuery);
