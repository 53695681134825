Fastweb.Widgets.CredibleWidget = (function($) {
  init = function() {
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.addEventListener('load', function(event) {
      CredibleSDK.initWidget('#student-loan-rates-table-shortened', {
        environment: 'production',
        product: {
          marketplace: 'student-loan',
          type:  'rate-table',
          variation: 'shortened',
        },
        ui: {
          theme: 'default',
        },
        analytics: {
          source: 'finaid',
        },
      })
    }); 

    script.src = 'https://sdk.credible.com/sdk.js';
    document.getElementsByTagName('head')[0].appendChild(script); 
  }

  return {init: init};

})(jQuery);
