Fastweb.Admin.StructuredData = (function($) {
  function checkIfTypeExists() {
    let types = []
    $('#structured_data_types > div').map(function () {
      types.push(this.id)
    })
    if (types.includes($('#data-type').val())) {
      $('.alert.callout').html('Type already exists. Please select a different type if needed.')
      $('.alert.callout').css('display', 'block')
    } else {
      Fastweb.Admin.StructuredData.changeStructuredDataTypes($('#data-type').val())
      $('.alert.callout').html('There are some errors in your form')
      $('.alert.callout').css('display', 'none')
    }
  }
  
  function changeStructuredDataTypes(type, values = '') {
    switch(type) {
      case 'ItemList':
        $("#structured_data_types").prepend(itemListType(values));
        break;
      case 'FAQPage':
        faqPageType(values)
        break;
      case 'WebSite':
        $("#structured_data_types").prepend(webSiteType(values));
        break;
      case 'WebPage':
        $("#structured_data_types").prepend(webPageType(values));
        break;
      }
    
  }

  function itemListType(values) {
    return `
      <div class='small-6 cell structured-data-input' id='ItemList'>
        <h3>Item List</h3>
        <div class='item-list-header'>
          <label for='data[item_list][elements]'>Item List Elements</label>
          <span class='item-list-header-count'>count: <span class='item-list-count-val'>${values.elements ? values.elements.length : ''}</span></span>
        </div>
        <textarea name='data[item_list][elements]' rows='8' cols='5' required>${values.elements ? values.elements.join("\n"): ''}</textarea>
        <p class='help-text'>Enter each item separated by new line</p>
        <p class='help-text'>Example: </br>Item 1 </br>Item 2</p>
        <label for='data[item_list][name]'>Item List Name</label>
        <input name='data[item_list][name]' type='text' required value="${values.name ? values.name : ''}" />
        <label for='data[item_list][order]'>Item List Order</label>
        <select name='data[item_list][order]' type='select' >
          <option value='https://schema.org/ItemListOrderAscending' selected="${values.order == 'https://schema.org/ItemListOrderAscending' ? 'selected': ''}">Ordered</option>
          <option value='https://schema.org/ItemListUnordered' selected="${values.order == 'https://schema.org/ItemListUnordered' ? 'selected': ''}">Unordered</option>
        </select>
        <button onclick="$(this).parent().remove();" class='button remove-button'>Remove</button>
      </div>
    `
  }

  function faqPageType(values = []) {
    if (values.length > 0) {
      html = `
      <div class='small-6 cell structured-data-input' id='FAQPage'>
        <h3>FAQ Page</h3>
        <div id='accordion'>
        ${values.map((value, i) => {
          return `
          <p style="margin-bottom: 0">Question</p>
          <div>
            <label for='data[faq][name][]'>Question Text</label>
            <input type='text' name='data[faq][name][]' value="${value.name}"/>
            <label for='data[faq][answer]'>Question Answer</label>
            <textarea rows='3' cols='3' name='data[faq][answer][]'>${value.text}</textarea>
            ${i == 0 ? '' : "<button class='button remove-button' onclick='Fastweb.Admin.StructuredData.deleteAccordionRow(event, this)'>Delete</button>"}
          </div>
          `
          }).join('')}
          </div>
        <button class='button' style='margin-top: 1rem' onClick='Fastweb.Admin.StructuredData.addQuestion(event)'>Add Question</button>
        <button onclick="$(this).parent().remove();" class='button remove-button'>Remove</button>
      </div>
      `
      } else {
        html = `
          <div class='small-6 cell structured-data-input' id='FAQPage'>
            <h3>FAQ Page</h3>
            <div id='accordion'>
              <p style="margin-bottom: 0">Question</p>
              <div>
                <label for='data[faq][name][]'>Question Text</label>
                <input type='text' name='data[faq][name][]' required/>
                <label for='data[faq][answer]'>Question Answer</label>
                <input type='text' name='data[faq][answer][]' required/>
              </div>
            </div>
          <button class='button' style='margin-top: 1rem' onClick='Fastweb.Admin.StructuredData.addQuestion(event)'>Add Question</button>
          <button onclick="$(this).parent().remove();" class='button remove-button'>Remove</button>
        </div>
        `
      }
      $("#structured_data_types").prepend(html);
      $("#accordion").accordion({ collapsible: true, active: true }).find("p").addClass("ui-state-default");
  }

  function webSiteType(values) {
    return `
      <div class='small-6 cell structured-data-input' id='WebSite'>
        <h3>Web Site</h3>
        <input name='data[web_site][display]' type='hidden' value='true'/>
        <label for='data[web_site][name]'></label>
        <input name='data[web_site][name]' type='text' value='Fastweb' disabled=true/>
        <label for='description'>Description</label>
        <input name='data[web_site][description]' type='text' disabled='true' value='Find scholarships and college information with Fastweb, the largest scholarship database for high school and college students.'/>
        <label for='url'>URL</label>
        <input name='data[web_site][url]' type='text' value='https://www.fastweb.com' disabled='true'x/>
        <button onclick="$(this).parent().remove();" class='button remove-button'>Remove</button>
      </div>
    `
  }

  function webPageType(values) {
    let url = $('#url').val() ?? ''
    return `
      <div class='small-6 cell structured-data-input' id='WebPage'>
        <h3>Web Page</h3>
        <label for='data[web_page][name]'>Name</label>
        <input name='data[web_page][name]' type='text' required value="${values.name ? values.name : ''}" />
        
        <label for='data[web_page][description]'>Description</label>
        <input name='data[web_page][description]' type='text' required value="${values.description ? values.description : ''}" />
        
        <button onclick="$(this).parent().remove();" class='button remove-button'>Remove</button>
      </div>
    `
  }

  function addQuestion(event) {
    event.preventDefault();
    $('#accordion').append(
      `
        <p style="margin-bottom: 0">Question</p>
        <div>
          <label for='data[faq][name][]'>Question Text</label>
          <input type='text' name='data[faq][name][]'/>
          <label for='data[faq][answer]'>Question Answer</label>
          <input type='text' name='data[faq][answer][]'/>
          <button class='button remove-button' onclick='Fastweb.Admin.StructuredData.deleteAccordionRow(event, this)'>Delete</button>
        </div>
      `
    ).accordion("refresh")
  }

  function deleteAccordionRow(event, element) {
    event.preventDefault();
    var parent = $(element).closest('div');
    var head = parent.prev('p');
    parent.add(head).fadeOut('slow',function(){$(this).remove();});
  }

  return {
    addQuestion: addQuestion,
    webPageType: webPageType,
    webSiteType: webSiteType,
    itemListType: itemListType,
    faqPageType: faqPageType,
    changeStructuredDataTypes: changeStructuredDataTypes,
    deleteAccordionRow: deleteAccordionRow,
    checkIfTypeExists: checkIfTypeExists
  }

})(jQuery);