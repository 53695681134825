Fastweb.ProfileQuestionsAutocomplete = (function($) {
  // available model names
  // ["fw_sport", "student_activity", "user_scholastic", "educational_experience", "fw_career_objective"]
  
  var added_fw_sport_ids = [];
  var added_student_activity_ids = [];
  var added_user_scholastic_ids = [];
  var added_educational_experience_ids = [];
  var added_fw_career_objective_ids = [];
  
  var addedIds = [];
  
  addedIds['fw_sport'] = added_fw_sport_ids;
  addedIds['student_activity'] = added_student_activity_ids;
  addedIds['user_scholastic'] = added_user_scholastic_ids;
  addedIds['educational_experience'] = added_educational_experience_ids;
  addedIds['fw_career_objective'] = added_fw_career_objective_ids;


  function addSelected(lookup, selected, ac_element, model) {
    /* we are assuming the the carbon endpoint lookup path and association on the model have the same name, the lookup path will be in plural
     * form so we need to strip off the trailing 's' to get the association name in singular form. If in the furture this functionality is needed
     * for other associations that like country or county this will have to be re-worked. */
    
    // var name = model + "[" + singularized_lookup + "_ids][]";
    var name = $(ac_element).attr('data-rel');
    var id = model + "_" + lookup + "_ids_";
    
    var acElementId = $(ac_element).attr('id');

    if(addedIds[lookup].indexOf(selected.item.value) == -1) {
      addedIds[lookup].push(parseInt(selected.item.value));

      $(ac_element).parent()
                   .siblings('ul.hidden_profile_questions')
                   .append(
                      "<li>" + selected.item.label + 
                      "<input id='" + id + "' type='hidden' name='" + name + "' value='" + selected.item.value + "'/>" +
                      "<a href='javascript:void(0);' onclick='$(this).parent().remove();var lookupType=Fastweb.ProfileQuestionsAutocomplete.addedIds[\"" + lookup + "\"];lookupType.splice(lookupType.indexOf(" + selected.item.value + "), 1); $(\"#" + acElementId + "\").closest(\"form\").foundation(\"validateInput\", $(\"#" + acElementId + "\"));return false;'><i class='fi-x'></i></a></li>"
                    );

      $('.ui-autocomplete').children().remove();
      $('.list_suggestion:containsi("' + selected.item.label + '")').remove();
    }
    
    $(ac_element).val("");
    $(ac_element).closest('form').foundation('validateInput', $(ac_element));
  }


  listAutoCompleteSelect = function(lookup, selected, ac_element) {
    var model = $(ac_element).attr('data-model');
    addSelected(lookup, selected, ac_element, model);
  }

  init = function(name) {
    $('[data-behavior=profile-questions-autocomplete]').each(function(index, element) {
      $(element).blur(function() {
        setTimeout(function(){ $(this).val(""); }, 100);
      });

      $(element).on('autocompleteOptions', function(event, data) {
        return $.map(data, function( item ) {
          return { label: item.name, value: item.id };
        });
      });

      var lookup = $(element).attr('data-autocomplete-lookup');
      $(element).on('autocompleteSelect', function(event, selected) {
        Fastweb.ProfileQuestionsAutocomplete.listAutocompleteSelect(lookup, selected, this);
      });
    });
  }

  triggerAutocomplete = function(element) {
    text = $(element).val();
    var dataSet = $(element).attr('data-set');
    dataSet = JSON.parse(dataSet);
    
    if (text.length < 2) return;
    
    $(element).autocomplete({
      source: dataSet,
      classes: {
        "ui-autocomplete": "f-dropdown white-bg",
      },
      minLength: 2,
      delay: 0,
      select: function(event, ui) {
        $(this).trigger('autocompleteSelect', [ui]);
        event.preventDefault();
      },
      focus: function(event, ui) {
        event.preventDefault();
        $(element).val(ui.item.label);
      },
      open: function(event, ui) {
        if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
          $('.ui-autocomplete').off('menufocus hover mouseover mouseenter');
        }
      }
    });
  }

  return {
    init: init,
    addedIds: addedIds,
    listAutocompleteSelect: listAutoCompleteSelect,
    triggerAutocomplete: triggerAutocomplete
  }

})(jQuery);

$(document).on('turbolinks:load', function () {
  $('[data-behavior=profile-questions-autocomplete]').keyup(function() {
    Fastweb.ProfileQuestionsAutocomplete.triggerAutocomplete(this)
  });
});
