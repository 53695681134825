

Fastweb.AdBlock = (function($) {

check = async () => {
  let results = []
  const blockedWebsites = ['https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js', 'https://acdn.adnxs.com/ast/ast.js']
  await Promise.all(
    blockedWebsites.map(async s => {
      try {
        let response = await fetch(s);
        results.push(response.ok)
      } catch {
        results.push(false)
      }
  }))

  return results
} 

checkAdBlock = async function() {
  let checks = await check()
  if (checks.includes(false) && $('.awards-show-page').length > 0) {
    $('#maincontent').prepend(`
      <div id="flash_notice" class="grid-x label success flash_notice" role="alert">
        <div>To provide you with the best experience possible, please disable ad blockers for fastweb.com.</div>
      </div>
      `)
    gtag('event', 'AdBlockDetected', {'event_category': 'AdBlockDetected', 'event_label': 'adBlockOn', 'value': true, 'ad_blockers': window.location.href});
  } else if (checks.includes(false)) {
    gtag('event', 'AdBlockDetected', {'event_category': 'AdBlockDetected', 'event_label': 'adBlockOn', 'value': true, 'ad_blockers': window.location.href});
  }
}

  return {checkAdBlock: checkAdBlock}

})(jQuery)



