
Fastweb.Subscriptions = (function($) {

  init = function() {
    $('.paused_subscription').click(function() {
      $(this).closest('.grid-x').hide();
      $(this).closest('.grid-x').next().removeClass('hide');
      $(this).closest('.grid-x').next().find('input').attr('disabled', false)
    });

    $('#pause_subscriptions_form').submit(function() {
      $(this).append("<input type='hidden' name='email' value='" + $('#email').val() +"' />");
    });

    $('#pause_subscriptions_form').submit(function() {
      $(this).append("<input type='hidden' name='ee' value='" + $('#ee').val() +"' />");
      $(this).append("<input type='hidden' name='email_list_id' value='" + $('#email_list_id').val() +"' />");
    });

  }

  return {init: init}

})(jQuery);
