Fastweb.MatchesFiltering = (function($) {

  init = function() {
    $('#filter-by-college').click(function(event){
      event.stopPropagation();
      $('#user-majors-container').hide();
      $('#user-location-container').hide();
      $('#user-colleges-container').toggle();
      $(this).addClass('active-college-list');
      $('#filter-by-location').removeClass('active-location-list');
      $('#filter-by-major').removeClass('active-major-list');
      $('.toggle-overlay').css('opacity', '0.3');
    });
    $('#filter-by-major').click(function(event){
      event.stopPropagation();
      $('#user-colleges-container').hide();
      $('#user-location-container').hide();
      $('#user-majors-container').toggle();
      $(this).addClass('active-major-list');
      $('#filter-by-college').removeClass('active-college-list');
      $('#filter-by-location').removeClass('active-location-list');
      $('.toggle-overlay').css('opacity', '0.3');
    });
    $('#filter-by-location').click(function(event){
      event.stopPropagation();
      $('#user-colleges-container').hide();
      $('#user-majors-container').hide();
      $('#user-location-container').toggle();
      $(this).addClass('active-location-list');
      $('#filter-by-college').removeClass('active-college-list');
      $('#filter-by-major').removeClass('active-major-list');
      $('.toggle-overlay').css('opacity', '0.3');
    });

    $(document).click(function(event){
      if ($(event.target).closest('#user-colleges-container').length === 0 &&
          $(event.target).closest('#user-majors-container').length === 0 &&
          $(event.target).closest('#user-location-container').length === 0) {
        $('.toggle-overlay').css('opacity', '1');
      }
      if ($(event.target).closest('#user-colleges-container').length === 0) {
        $('#user-colleges-container').hide();
        $('#filter-by-college').removeClass('active-college-list');
      }
      if ($(event.target).closest('#user-majors-container').length === 0) {
        $('#user-majors-container').hide();
        $('#filter-by-major').removeClass('active-major-list');
      }
      if ($(event.target).closest('#user-location-container').length === 0) {
        $('#user-location-container').hide();
        $('#filter-by-location').removeClass('active-location-list');
      }
    });
  }

  selectAllCheckboxes = function(item) {
    var array = document.getElementsByClassName(item);
    for(var i = 0; i < array.length; i++) {
      var eachItem = document.getElementById(array[i].id);
      eachItem.checked = true;
    }
  }
  clearAllCheckboxes = function(item) {
    var array = document.getElementsByClassName(item);
    for(var i = 0; i < array.length; i++) {
      var eachItem = document.getElementById(array[i].id);
      eachItem.checked = false;
    }
  }

  return {
    init: init,
    selectAllCheckboxes: selectAllCheckboxes,
    clearAllCheckboxes: clearAllCheckboxes
  }

})(jQuery);
