import { screenLeftCenter, screenTopCenter } from './utilities.js'

Fastweb.InviteAFriend = (function($) {

  init = function() {
    const invitationText = 'Sign up for Fastweb with this link and we could both win $500!';
    const specs = `location=no,toolbar=0,status=yes,resizable=1,width=640,height=436`;
    const referralLink = $("#user_referral_link").val();
    
    // ---------- SOCIAL MEDIA ----------

    $("#twitter_link").on('click', function(e) {
      e.preventDefault();
      const url = encodeURI(`https://twitter.com/intent/tweet?text=${invitationText}&url=`) + encodeURIComponent(`${referralLink}?utm_source=refer&utm_medium=twitter&utm_campaign=friend&via=payingforschool`);
      window.open(url, '', specs);
    });

    $("#pinterest_link").on('click', function(e) {
      e.preventDefault();
      const fw_logo_url = 'https://www.fastweb.com/nfs/fastweb/static/fastweb_logo.png';
      const url = encodeURI(`https://www.pinterest.com/pin/create/button/?media=${fw_logo_url}&description=${invitationText}&url=`) + encodeURIComponent(`${referralLink}?utm_source=refer&utm_medium=pinterest&utm_campaign=friend&via=payingforschool`);
      window.open(url, '', specs);
    });

    $("#fb_link").on('click', function(e) {
      e.preventDefault();
      const url = 'https://facebook.com/sharer/sharer.php?u=' + encodeURIComponent(`${referralLink}?utm_source=refer&utm_medium=facebook&utm_campaign=friend&via=payingforschool`);
      window.open(url, '', specs);
    });

    // ---------- COPY TEXT ----------

    $('#copy-text').on('click', function() {
      let referralInput = document.getElementById('user_referral_link');
      referralInput.select();
      referralInput.setSelectionRange(0, 99999);
      document.execCommand('copy');
      $('#text-copied').show().delay(4000).fadeOut();
    })
  }

  return {init: init};

})(jQuery);
