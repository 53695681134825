/*
 * Autocomplete implemention for Fastweb.
 * Usage:
 * Autocomplete elements must define the `data-behavior="fastweb-autocomplete"` attribute as well as the data attribute
 * 'data-autocomplete-lookup' which specifies the carbon endpoint to use as a lookup for the autocomplete data.
 * E.g.
 * <input type="text" data-behavior="fastweb-autocomplete" data-autocomplete-lookup="fw_majors" id="fw_majors_autocomplete" />
 *
 * Two events handler callbacks should also be defined to determine autocomplete behavior with an optional third event handler to
 * handle when no autocomplete results are returned.
 * The first callback should handle the 'autocompleteOptions' event which will format the source data for the autocomplete element.
 * The callback should accept two parameters, the first param being the event, the second param being the data returned from the server
 * during the lookup
 * E.g.
 * $("#fw_majors_autocomplete").on('autocompleteOptions', function(event, data) {
 *   return $.map(data, function( item ) {
 *     return { label: item.name, value: item.id };
 *   });
 * });
 *
 * The second callback should handle the 'autocompleteSelect' event which will determine how the autocomplete should behave when a
 * result is selected. The callback should accept two parameters, the first param being the event, the second param being the autocomplete
 * element selected.
 * E.g.
 * $('#fw_majors_autocomplete').on('autocompleteSelect', function(event, selected) {
 *   alert('You have selected ' + selected.label);
 *   $(this).val(select.label);
 * });
 *
 * The third OPTIONAL callback should handle the 'autocompleteNoResults' event which will determine how the autocomplete should behave when no
 * suggested results are available. The callback can return data formatted for the autocomplete source parameter to suggest a default value.
 * The callback should accept a single parameter of the event.
 * E.g.
 * $('#fw_majors_autocomplete').on('autocompleteNoResults' function(event) {
 *   return [{label: 'No Results Found', value: null}];
 * });
*/


Fastweb.Autocomplete = (function($) {

  // Param: element - the element the autocomplete results should be attached to
  triggerAutocomplete = function(element) {
    lookup = $(element).attr('data-autocomplete-lookup');
    text = $(element).val();

    if (text.length < 2) return;
    url = document.location.origin + '/carbon/' + lookup + ".json";

    $(element).autocomplete( {
      source: function(request, response) {
        text = $(element).val();
        $.ajax({
          url: url,
          dataType: "json",
          data: {q: text},
          cache: false
        })
        .done(function(data, textStatus, jqXHR) {
          var options = $(element).triggerHandler('autocompleteOptions', [data]);
          if(options == undefined || options.length == 0) {
            options = $(element).triggerHandler('autocompleteNoResults');
          }

          response(options);
        })
        .fail(function(jqHXR, textStatus, errorThrown) {
          console.log("Failed to retrieve autocomplete results - " + errorThrown);
        });
      },
      classes: {
        "ui-autocomplete": "f-dropdown white-bg",
      },
      minLength: 2,
      delay: 0,
      select: function(event, ui) {
        $(this).trigger('autocompleteSelect', [ui]);
        event.preventDefault();
      },
      focus: function(event, ui) {
        event.preventDefault();
        $(element).val(ui.item.label);
      },
      open: function(event, ui) {
        if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
          $('.ui-autocomplete').off('menufocus hover mouseover mouseenter');
        }
      }
    } );

  }

  return {triggerAutocomplete: triggerAutocomplete}

})(jQuery);


$(document).on('turbolinks:load', function () {
  $('[data-behavior=fastweb-autocomplete]').keyup(function() {
    Fastweb.Autocomplete.triggerAutocomplete(this)
  });
});

