
Fastweb.Admin.AmpValidator = (function($) {
  init = function() {
    postArticleHtmlBody();
    toggleLineWrap();
  }

  function postArticleHtmlBody() {
    $('.amp-validation-btn').click(function() {
      $(this).css('pointer-events', 'none');
      $('.button-with-spinner .spinner-circle').show();
      const articleSlug = $(this).attr('data-article-slug');
      let articleBody = $('#article-editor').val();
      articleBody = btoa(unescape(encodeURIComponent(articleBody)));

      $.post({
        url: '/admin/articles/article_html',
        data: {
          body: articleBody
        },
        dataType: 'json'
      })
      .done(function(data, textStatus, jqXHR) {
        validateAmpHtml(data.content);
      })
      .fail(function(){
        let errorsList = $(document.createElement('ul')).attr('id', 'article-errors-list')[0];
        let errorMsg = 'Unable to validate AMP';
        let li = document.createElement('li');
        li.appendChild(document.createTextNode(errorMsg));
        errorsList.appendChild(li);

        showErrorsList(errorsList);
      })
    });
  }

  function validateAmpHtml(ampHtml) {
    const validator = window.amp.validator;
    validator.init().then(() => {
      const result = validator.validateString(ampHtml);
      let errorsList = $(document.createElement('ul')).attr('id', 'article-errors-list')[0];
      if (result.status === 'PASS') {
        const successMsg = 'AMP validation PASSED';
        let li = $(document.createElement('li')).attr('id', 'article-success-msg')[0];
        li.appendChild(document.createTextNode(successMsg));
        errorsList.appendChild(li);
      } else {
        let ampHtmlLines = ampHtml.split('\n');
        const mainContentDiv = "<div class='off-canvas-content' data-off-canvas-content='' id='maincontent' role='main'>";
        const lineOffset = ampHtmlLines.indexOf(mainContentDiv) + 1;

        for (let i = 0; i < result.errors.length; i++) {
          let error = result.errors[i];
          let adsOffset = ampAdsCountBeforeError(ampHtmlLines, error.line, lineOffset);
          let msg = `line ${error.line - lineOffset - adsOffset}: ${error.code}`;
          if (error.specUrl) {
            msg += ' (see ' + error.specUrl + ')';
          }
          if (error.severity === 'ERROR') {
            let li = document.createElement('li');
            li.appendChild(document.createTextNode(msg));
            errorsList.appendChild(li);
          }
        }
      }
      
      showErrorsList(errorsList);
    })
  }

  function ampAdsCountBeforeError(ampHtmlLines, errorLine, lineOffset) {
    const ampAdRegex = /(<amp-ad).*[\r\n]*(<\/amp-ad>)/i;
    let count = 0;
    
    for(let i = lineOffset; i < errorLine; i++) {
      if (ampHtmlLines[i].match(ampAdRegex)) count++;
    }
    // 1 amp ad = 3 amp html lines
    return 3 * count;
  }

  function showErrorsList(errorsList) {
    $('.button-with-spinner .spinner-circle').hide();
    $('.amp-validation-btn').css('pointer-events', 'auto');
    $('.amp-errors').html(errorsList);
    $('.amp-errors').show();
  }

  function toggleLineWrap() {
    $('#toggle-wrap a').click(function() {
      let wrapText = $(this).text();
      if (wrapText == 'Off') {
        $(this).text('On');
        $('#article-editor').attr('wrap', 'on');
      } else {
        $(this).text('Off');
        $('#article-editor').attr('wrap', 'off');
      }
    })
  }

  return { init: init }

})(jQuery);
