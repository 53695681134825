Fastweb.Admin.FeaturedAwardsForm = (function($) {

  function initializeAwardsAutocomplete(element) {
    $(element).keyup(function() {
      Fastweb.Autocomplete.triggerAutocomplete(this)
    });

    $(element).on('autocompleteOptions', function(event, data) {
      return $.map( data, function( item ) {
        return { label: item.name + " - #" + item.id + " - (" + item.status_txt + ")", value: item.id };
      });
    });

    var lookup = $(element).attr('data-autocomplete-lookup');
    $(element).on('autocompleteSelect', function(event, selected) {
      $(this).siblings(".featured_db_award_id").attr("value", selected.item.value);
      $(this).val(selected.item.label);
    });
  }

  function featuredDbListingForm(values = {}) {
    const check_box = $('#featured_db_listing')
    if ('award_id' in values) {
      check_box.prop('checked', true)
    }
    const form = $('#featured-db-listing-form')
    const start_at = getDatesForForm('start')
    const end_at = getDatesForForm('end')
    if (check_box.prop('checked')) {
      form.append(`
        <label for>Award ID/Name</label>
        <input type="text" name="search" id="search" placeholder="Search Awards" class="ui-autocomplete-input" data-autocomplete-lookup="awards" data-behavior='fastweb-autocomplete' value='${values.award_id ? values.award_id : ''}'>
        <input type='hidden' name='featured_db_listing[award_id]' class='featured_db_award_id' value='${values.award_id ? values.award_id : ''}'/>
        <label for>Start Date</label>
        <input type='date' name='featured_db_listing[start_date]' value='${values.start_date ? values.start_date : start_at}'/>
        <label>End Date</label>
        <input type='date' name='featured_db_listing[end_date]' value='${values.end_date ? values.end_date : end_at}' min='${start_at}'/>
        <label>Impression Pixel</label>
        <input type='text' name='featured_db_listing[impression_pixel]' onblur="Fastweb.Admin.FeaturedAwardsForm.validateImpressionPixel(this)" value='${values.impression_pixel ? values.impression_pixel : ''}'/>
        <span class="form-error" id="impression_error">The 'src' property url must use the https protocol.</span>
        <label>Pinned Order</label>
        <select name='featured_db_listing[pinned_order]' value='${values.pinned_order ? values.pinned_order : ''}'>
          <option value='-1'>Never</option>
          <option value='0'>Always</option>
          <option value='1'>1</option>
          <option value='2'>2</option>
          <option value='3'>3</option>
          <option value='4'>4</option>
          <option value='5'>5</option>
          <option value='6'>6</option>
          <option value='7'>7</option>
          <option value='8'>8</option>
          <option value='9'>9</option>
          <option value='10'>10</option
        </select>
      `)
      Fastweb.Admin.FeaturedAwardsForm.initializeAwardsAutocomplete($('#search'))
    } else {
      $("#save-button").attr("disabled", false);
      form.empty()
    }
  }

  function futureActivationForm() {
    const check_box = $('#future_activation')
    const form = $('#future-activation-form')
    const start_at = getDatesForForm('start')
    const end_at = getDatesForForm('end')
    let defaultFuture = new Date()
    defaultFuture.setDate(defaultFuture.getDate() + 1)
    defaultFuture = defaultFuture.toISOString().split('T')[0] 
    if (check_box.prop('checked')) {
      form.append(`
        <input type='date' name='featured_scholarship[future_date]' min=${defaultFuture} max=${end_at} value=${defaultFuture} id='future' onkeydown="return false"/>
        <p class='help-text'>Setting the future date will update the respective featured award with these values at the selected future date. (This does not have any impact to the current award until future date).</p>
        <input type='submit' name='future_activation' class='button' value='Future'/>
      `)
    } else {
      form.empty()
    }
  }

  function getDatesForForm(input) {
    return new Date(parseInt($(`#featured_scholarship_${input}_at_1i`).val()), parseInt($(`#featured_scholarship_${input}_at_2i`).val()) - 1, parseInt($(`#featured_scholarship_${input}_at_3i`).val())).toISOString().split('T')[0]
  }

  function validateImpressionPixel(element) {
    value = $(element).val()
    if(/src\s*=\s*["|'](\S*)["|'][\s|>|\/>]/i.test(value) || value == "") {
      $("#impression_error").css("display", "none");
      $("#save-button").attr("disabled", false);
    } else {
      $("#save-button").attr("disabled", true);
      $("#impression_error").css("display", "block");
    }
  }

  return { init: init,
          featuredDbListingForm: featuredDbListingForm,
          initializeAwardsAutocomplete: initializeAwardsAutocomplete,
          validateImpressionPixel: validateImpressionPixel,
          futureActivationForm: futureActivationForm
  }

})(jQuery);
