Fastweb.CollegesAndMajorsAutocomplete = (function($) {
  var added_college_ids = [];
  var added_fw_major_ids = [];
  var addedIds = [];
  addedIds["colleges"] = added_college_ids;
  addedIds["fw_majors"] = added_fw_major_ids;

  function addSelected(lookup, selected, ac_element, model, submitButton) {
    /* we are assuming the the carbon endpoint lookup path and association on the model have the same name, the lookup path will be in plural
     * form so we need to strip off the trailing 's' to get the association name in singular form. If in the future this functionality is needed
     * for other associations that like country or county this will have to be re-worked. */
    var singularized_lookup = lookup.substring(0, lookup.length - 1);
    var name = model + "[" + singularized_lookup + "_ids][]";
    var id = model + "_" + singularized_lookup + "_ids_";
    var acElementId = $(ac_element).attr('id');

    if(addedIds[lookup].indexOf(selected.item.value) == -1) {
      addedIds[lookup].push(parseInt(selected.item.value));
      $(ac_element).parent()
                   .siblings('ul.hidden_' + lookup)
                   .append(
                      "<li>" + selected.item.label + 
                      "<input id='" + id + "' type='hidden' name='" + name + "' value='" + selected.item.value + "'/>" +
                      "&nbsp; <a href='javascript:void(0);' onclick='$(this).parent().remove();Fastweb.RegistrationStep2.validateColleges(\"ul.hidden_" + lookup + "\",$(\"#" + submitButton + "\"));Fastweb.RegistrationStep2.validateMajors(\"ul.hidden_" + lookup + "\",$(\"#" + submitButton + "\"));var lookupType=Fastweb.CollegesAndMajorsAutocomplete.addedIds[\"" + lookup + "\"];lookupType.splice(lookupType.indexOf(" + selected.item.value + "), 1); $(\"#" + acElementId + "\").closest(\"form\").foundation(\"validateInput\", $(\"#" + acElementId + "\"));return false;'><i class='fi-x' data-remove></i></a></li>"
                    );

      $('.ui-autocomplete').children().remove();
      $('.list_suggestion:containsi("' + selected.item.label + '")').remove();
    }

    $(ac_element).val("");
    $(ac_element).closest('form').foundation('validateInput', $(ac_element));
    
    if (lookup === 'colleges') {
      Fastweb.RegistrationStep2.validateColleges('ul.hidden_colleges','#submit-question');
    } else if (lookup === 'fw_majors') {
      Fastweb.RegistrationStep2.validateMajors('ul.hidden_fw_majors','#submit-question');
    }
  }

  listAutoCompleteSelect = function(lookup, selected, ac_element) {
    var submitButton = $('#submit-question').attr('id');
    if(selected.item.value == "userSubmittedCollege") {
      addUserSubmittedCollege(lookup, selected, ac_element, submitButton);
    } else {
      var model = $(ac_element).attr('data-model');
      addSelected(lookup, selected, ac_element, model, submitButton);
    }
  }

  function addUserSubmittedCollege(lookup, selected, ac_element, submitButton) {
    var new_id = new Date().getTime();
    var college_name = selected.item.collegeName;
    var acElementId = $(ac_element).attr('id');
    $(ac_element).parent()
                 .siblings('ul.hidden_' + lookup)
                 .append(
                    "<li>" + college_name +
                    "<a href='javascript:void(0);' onclick='$(this).parent().remove();Fastweb.RegistrationStep2.validateColleges(\"ul.hidden_" + lookup + "\",$(\"#" + submitButton + "\"));$(\"#" + acElementId + "\").closest(\"form\").foundation(\"validateInput\", $(\"#" + acElementId + "\"));return false;'><i class='fi-x'></i></a></li>");
    
    $('<input>').attr({type: 'hidden', name: 'user[user_submitted_colleges_attributes][' + new_id + '][name]', value: college_name}).appendTo($(ac_element).parent().siblings('ul.hidden_' + lookup).children().last());
    
    $(ac_element).val("");
    $(ac_element).closest('form').foundation('validateInput', $(ac_element));
    
    if($('ul.hidden_' + lookup).children().length != 0) {
      if (!$('#' + submitButton).hasClass('valid-form')) $('#'+submitButton).addClass('valid-form');
    }
  }

  initUserSubmittedColleges = function() {
    $('[data-behavior=fastweb-autocomplete]').each(function(index, element) {
      $('#users_colleges_filters').on('autocompleteNoResults', function() {
        return [{label: "Add \"" + $(this).val() + "\" to your profile", value: "userSubmittedCollege", collegeName: $(this).val()}]
      });
    });
  }

  init = function() {

    $('input[name="user[college_ids][]"]').each(function(index, element) {
      added_college_ids.push(parseInt($(element).prop('value')));
    });
    $('input[name="user[fw_major_ids][]"]').each(function(index, element) {
      added_fw_major_ids.push(parseInt($(element).prop('value')));
    });

    $('[data-behavior=fastweb-autocomplete]').each(function(index, element) {
      $(element).blur(function() {
        setTimeout(function(){ $(this).val(""); }, 100);
      });

      $(element).on('autocompleteOptions', function(event, data) {
        return $.map(data, function( item ) {
          if(url.indexOf('colleges') !== -1) {
            var name_with_location = '';
            if(item.name.indexOf(item.city) !== -1) {
              name_with_location += item.name + ' - ' + item.state;
            } else {
              name_with_location += item.name + ' - ' + item.city + ', ' + item.state;
            }
            return { label: name_with_location, value: item.id };
          } else {
            return { label: item.name, value: item.id };
          }
        });
      });

      var lookup = $(element).attr('data-autocomplete-lookup');
      $(element).on('autocompleteSelect', function(event, selected) {
        Fastweb.CollegesAndMajorsAutocomplete.listAutocompleteSelect(lookup, selected, this);
      });
    });

  }

  return {init: init, initUserSubmittedColleges: initUserSubmittedColleges, listAutocompleteSelect: listAutoCompleteSelect, addedIds: addedIds}

})(jQuery);
