Fastweb.Index = (function($) {

  applySotdPadding = function() {
    var sotdH = $('.sotd').innerHeight();
    $('.home_top_scholarships .featured_scholarships_content').css({'padding-top': sotdH});
  }


  return {applySotdPadding: applySotdPadding};


})(jQuery);
