
function trackClick(trackingUrl) {
  if(typeof(trackingUrl) != 'undefined' && trackingUrl.length > 0) {
    $.get(trackingUrl)
      .done(function() {
        return true;
      })
      .fail(function() {
        // no-op
      });
  }

  return true;
}

