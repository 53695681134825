
/*
 * Extend jQuery to have a case-insensitive version of its :contains selector
 */
jQuery.extend(jQuery.expr[':'], {
  'containsi': function(elem, i, match, array)
  {
    return (elem.textContent || elem.innerText || '').toLowerCase()
    .indexOf((match[3] || "").toLowerCase()) >= 0;
  }
});
