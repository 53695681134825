
Fastweb.MemberProfile = (function($) {

  init = function(active_tab) {
    $('#profile_tabs').foundation('selectTab', $('#' + active_tab), true);

    $('[data-behavior=edit-field]').on('click', function() {

      $.ajax({
        url: window.location.origin + window.location.pathname + '/edit_profile_field',
        data: {active_tab: $(this).closest('.tabs-panel').attr('id'), profile_field: $(this).attr('data-profile-field')},
        dataType: 'script'
      })
      .fail(function() {
        window.location = window.location.href;
      });

      $("html, body").animate({ scrollTop: $('.tabs-content').offset().top }, "slow");

    });

  }

  initCheckboxListForm = function() {
    $(".search_textfield").keyup(function() {
	    text = $(this).val();
	    $(this).parent().parent().siblings(":not(:containsi('" + text + "'))").addClass('hide');
	    $(this).parent().parent().siblings(":containsi('" + text + "')").removeClass('hide');
	  });
  }

  initAddressValidation = function() {
    $('#save_full_address_button').on('click', function(e) {
      e.preventDefault();
      Fastweb.UserLocation.validateUserLocation("full_address_form");
      $("#full_address_form").foundation('validateForm');
    });
  }


  return {init: init, initCheckboxListForm: initCheckboxListForm, initAddressValidation: initAddressValidation}

})(jQuery);
