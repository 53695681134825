
Fastweb.Jobs = (function($) {

  init = function() {

    $('[data-behavior=increment-click-count]').click(function() {
      url = $(this).data('increment-click-count-url');
      $.ajax({
        url: url,
        type: 'post',
        dataType: 'script',
        data: null,
        cache: false,
        async: false
      });

      var alias = $(this).data('job-alias');
      var title = $(this).data('job-title');
      var company = $(this).data('job-company');
    });
  }

  return {init: init}

})(jQuery);
