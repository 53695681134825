
Fastweb.Promotions = (function($) {
  init = function() {
    // ---------- COPY TEXT ----------

    $('#copy-button').on('click', function() {
      let range = document.createRange();
      let promoCode = document.getElementById('promo-code');
      range.selectNode(promoCode);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand('copy');
      window.getSelection().removeAllRanges();
      
      let copyButtonText = document.getElementById('copy-button');
      copyButtonText.textContent = 'Copied';
      setTimeout(() => {
        copyButtonText.textContent = 'Copy';
      }, 2000);
    })
  }

  initCarousel = function() {
    $('.orbit').on('slidechange.zf.orbit', function(element, slide) {
      var bgColor = $(slide).attr('data-bg-color');
      var screenSize = $(slide).attr('data-screen-size')
      if(bgColor != '') {
        $('div.carousel_wrap.' + screenSize).css('background-color', bgColor);
      } else {
        $('div.carousel_wrap.' + screenSize).css('background-color', 'transparent');
      }
    });
  }

  return {init: init, initCarousel: initCarousel};

})(jQuery);
