Fastweb.Lendingtree = (function($) {

  var ltApiBaseUrl = 'https://www.simpletuition.com';

  /****************
   * ac_element - element to attach the autocomplete to
   ****************/
  schoolLookupAutocomplete = function(ac_element) {
    $(ac_element).autocomplete({
        delay: 300,
        minLength: 2,
        source: function(request, response) {
          text = $(ac_element).val();
          $.ajax({
            url: ltApiBaseUrl + '/api/v2/school_search',
            dataType: "json",
            data: {term: text,
                   cnt: 10},
            timeout: 1000
          })
          .done(function(data, textStatus, jqXHR) {
            var options = $.map(data['ac'], function( school ) {
              return { label: school.name, value: school.doe };
            });
            if(options == undefined || options.length == 0) {
              options = {label: 'No results found for "' + text + '".'};
            }

            response(options);
          })
          .fail(function(jqHXR, textStatus, errorThrown) {
            console.log("Failed to retrieve autocomplete results - " + errorThrown);
            var options = {label: "Sorry, we're experiencing some technical difficulties. Please try again later."};
            response(options);
          });
        },
        select: function(event, ui) {
          if($('#doe_code_lt').length > 0) {
            $('#doe_code_lt').remove();
          }
          $(ac_element).val(ui.item.label);
          $(ac_element).after('<input id="doe_code_lt" name="doe_code_lt" type="hidden" value="' + ui.item.value + '" />');
          event.preventDefault();
        }

      });

  }

  /****************
   * ac_element - element that contains the name of the college being used for search
   * autoScroll - boolean indicator is the page should auto scroll after a successful retrieval of loan results
   ****************/
  getRates = function(ac_element, autoScroll) {
    $.ajax({
      url: ltApiBaseUrl + '/api/v2/loan_search',
      dataType: "json",
      data: {doeCode: $('#doe_code_lt').val(),
             ipedsCode: $('#ipeds_id_lt').val(),
             borrowerType: $('input.borrower_type_lt[type=radio]:checked').val(),
             amt: $('#loan_amount_lt').val().replace(/\D/g, ''),
             gradDate: $('#date_year_lt').val(),
             zipCode: $('#zip_code_lt').val(),
             'WT.mc_id': '40600',
             brnd: 'fastweb',
             mtaid: '1B592'}
    })
    .done(function(data, textStatus, jqXHR) {
      if(data["results"] != undefined && data["results"].length > 0) {
        $('.no_lenders').hide();
        $('.lender_list').html('<ul></ul>')
        if(autoScroll) {
          $('html,body').animate({
            scrollTop: $(".our_top_lenders").offset().top
          }, 400, 'linear');
        }
        $.map(data['results'], function(lender, i) {
          var details = '';
          if(lender.details.length > 0) {
            lender.details.some(function(detail) {
              if(detail.length <= 100) {
                details = detail;
              }
              return detail.length < 100;
            });
            if(details == '') {
              details = lender.details[0].slice(0,100)
            }
          }
          $('.no_lenders').html('<h3>Offers we found for ' + $(ac_element).val() + '.</h3>');
          $('.no_lenders').show();

          var fixedApr = parseApr(lender.fixed, 'fixed');
          var variableApr = parseApr(lender.variable, 'variable');

          $('.lender_list > ul').append('<li> \
            <ul class="grid-x"> \
              <li class="logo-term_length small-12 medium-2"> \
                <div alt="Education Loan Finance" class="logo"> \
                  <img alt="Lender Logo" src="' + ltApiBaseUrl + lender.lenderLogoLarge + '"> \
                </div> \
              </li> \
              <li class="small-6 medium-2 fix"> \
                <h4>' + fixedApr['type'] + '</h4> \
                <p>' + fixedApr['rate'] + '</p> \
              </li> \
              <li class="small-6 medium-2 var"> \
                <h4>' + variableApr['type'] + '</h4> \
                <p>' + variableApr['rate'] + '</p> \
              </li> \
              <li class="small-12 medium-2 credit"> \
                ' + details + ' \
              </li> \
              <li class="small-12 medium-2 apply"> \
                <a class="button ghost" rel="noreferrer" target="_blank" href="' + ltApiBaseUrl + lender.applyURL +'">Apply Now</a> \
                <a class="view_details" href="javascript:void(0)" onclick="$(\'#lender_' + i + '_disclosures\').toggle()"> \
                  <i class="fi-plus"></i> \
                  View Disclosures \
                </a> \
              </li> \
            </ul> \
          </li> \
          <li id="lender_' + i + '_disclosures" class="disclosures" style="display: none"> \
            <div class="close_disclosures"> \
              <a href="javascript:void(0)" onclick="$(\'#lender_' + i + '_disclosures\').hide()">Close</a> \
            </div> \
            <div> \
            ' + lender.disclosures + ' \
            </div> \
            <div class="close_disclosures"> \
              <a href="javascript:void(0)" onclick="$(\'#lender_' + i + '_disclosures\').hide()">Close</a> \
            </div> \
          </li>');
        });
      } else {
        if($('#college').val().length > 0) {
          $('.no_lenders').html('<h3>We couldn\'t find any offers for ' + $('#college').val() + '. Use the \
            <a href="#lendingtree_page">above form</a> to try another search.</h3>');
        } else {
          $('.no_lenders').html('<h3>Please enter a school in the \
            <a href="#lendingtree_page">above form</a> to see loan offers.</h3>');
        }
        $('.no_lenders').show();
      }
    })
    .fail(function(jqHXR, textStatus, errorThrown) {
      $('.no_lenders').html('<h3>Sorry, we\'re experiencing some technical difficulties. Plase try again later.</h3>');
      $('.no_lenders').show();
    });
  }

  widgetGetRates = function(ipedsCode, schoolName) {
    $.ajax({
      url: ltApiBaseUrl + '/api/v2/loan_search',
      dataType: "json",
      data: {ipedsCode: ipedsCode,
             'WT.mc_id': '40600',
             brnd: 'fastweb',
             mtaid: '1B592'}
    })
    .done(function(data, textStatus, jqXHR) {
      if(data["results"] != undefined && data["results"].length > 0) {
        $('.lenders_list').html('')

        $.map(data['results'], function(lender, i) {
          if(i < 3) {
            var fixedApr = parseApr(lender.fixed, 'fixed');

            $('.lendingtree_widget > .title').text('Top Private Student Loan Lenders for ' + schoolName );
            $('.lenders_list').append('<li> \
              <div class="lender"> \
                <img alt="Lender Logo" src="' + ltApiBaseUrl + lender.lenderLogoLarge + '"> \
              </div> \
              <div class="rate_wrap"> \
                <div class="apr"> \
                  ' + fixedApr['type'] + ' \
                </div> \
                <div class="rate"> \
                  ' + fixedApr['rate'] + ' \
                </div> \
              </div> \
              <div class="button_wrap"> \
                <a class="lender_link button ghost" rel="noreferrer" target="_blank" href="' + ltApiBaseUrl + lender.applyURL +'">Visit Lender</a> \
                <a class="view_details" href="javascript:void(0)" onclick="$(\'#lender_' + i + '_disclosures\').toggle()"> \
                  <i class="fi-plus"></i> \
                  View Disclosures \
                </a> \
              </div> \
            </li> \
            <li id="lender_' + i + '_disclosures" class="disclosures" style="display: none"> \
              <div class="close_disclosures"> \
                <a href="javascript:void(0)" onclick="$(\'#lender_' + i + '_disclosures\').hide()">Close</a> \
              </div> \
              <div> \
              ' + lender.disclosures + ' \
              </div> \
              <div class="close_disclosures"> \
                <a href="javascript:void(0)" onclick="$(\'#lender_' + i + '_disclosures\').hide()">Close</a> \
              </div> \
            </li>');
          }
        });
      } else {
        $('.lendingtree_widget.logged_out').show();
        $('.lendingtree_widget.logged_in').hide();
      }
    })
    .fail(function(jqHXR, textStatus, errorThrown) {
      $('.lendingtree_widget.logged_out').show();
      $('.lendingtree_widget.logged_in').hide();
    });
  }

  function parseApr(lenderRate, rateType) {
    var re = new RegExp(rateType, "i");
    var aprRate = 'N/A';
    var aprType = rateType.charAt(0).toUpperCase() + rateType.slice(1) + ' APR';
    if(lenderRate != undefined) {
      aprType = lenderRate.slice(lenderRate.search(re));
      aprRate = lenderRate.slice(0, lenderRate.search(re) - 1);
    }

    return {'type': aprType.charAt(0).toUpperCase() + aprType.slice(1), 'rate': aprRate};
  }

  return {schoolLookupAutocomplete: schoolLookupAutocomplete, getRates: getRates, widgetGetRates: widgetGetRates}

})(jQuery);
