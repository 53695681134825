Fastweb.ProfileQuestions = (function($) {

  init = function() {
    var ac_element = '#user_profile_questions_filter';
    var lookup = $(ac_element).attr('data-autocomplete-lookup');
    Fastweb.ProfileQuestionsAutocomplete.init();

    $('[data-behavior=add-popular-profile-question]').click(function() {
      Fastweb.ProfileQuestionsAutocomplete.listAutocompleteSelect(lookup, {item: {value: $(this).attr('data-item-value'), label: $(this).attr('data-item-label')}}, ac_element);
      $(this).parent().remove();
    });
  }

  animateSubmitButton = function(animation, element) {
    $(animation).hide();
    $(element).click(function(){
      $(element).val('');
      $(animation).show();

      setTimeout(function(){
        $(element).val('NEXT');
        $(animation).hide();
      }, 1000);
    });  
  }

  return {init: init, animateSubmitButton: animateSubmitButton};

})(jQuery);
