
Fastweb.Awards = (function($) {

  init = function() {

    $('[data-behavior=increment-click-count]').click(function() {
      url = $(this).data('increment-click-count-url');
      $.ajax({
        url: url,
        type: 'post',
        dataType: 'script',
        data: null,
        cache: false,
        async: false
      });
    });

    $('.featured-award-detail').on('click', function(e) {
      var id = $(this).data('award-id');
      gtag('set', {'featured_award_flag': id});
      var name = $(this).data('award-name');
      if (name != undefined) {
        gtag('event', 'featured_award_apply_click', {'Award Name': name, 'Award ID': id});
      }

      var klass = $(this).data('klass');
      if(typeof(klass) != 'undefined' && klass.length > 0) {
        $.get(window.origin + '/tracking/track_campaign_click/' + klass + '/' + id)
          .always(function() { return true; });
      }

      return true;
    });

    $('#login_or_sign_up_apply_modal').on('closed.zf.reveal', function(){
      const referredFromEmail = window.location.search.includes('utm_medium=email');
      const showLoginTab = referredFromEmail || window.location.pathname == '/login' || window.location.pathname == '/logout';
      if (showLoginTab) {
        $('#login_sign_up').foundation('selectTab', $('#login_panel'), false);
      } else {
        $('#login_sign_up').foundation('selectTab', $('#sign_up_panel'), false);
      }
    })
  }

  return {init: init}

})(jQuery);
