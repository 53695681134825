(function($) {
  /* Used to inject html into a form to add fields for a model association */
  add_fields = function(element, association, content) {
  	var new_id = new Date().getTime();
  	var regexp = new RegExp("new_" + association, "g");
    $(element).before(content.replace(regexp, new_id));
  }

  /* Used to remove fields from a form to a model association.
   * Hides the fields from the view, and sets the hidden "destroy" input to true
   * so the association is destroyed on save
   */
  remove_answer_fields = function(link, css_class) {
  	$(link).prev("input[type=hidden]").val("1");
  	$(link).closest("." + css_class).hide();
  }

  return {
    add_fields: add_fields,
    remove_answer_fields: remove_answer_fields
  }

})(jQuery);
