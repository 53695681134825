Fastweb.Prebid = (function($) {

  async function initAdserver(pbjs, apntag) {
    return new Promise(function(resolve, reject) {
      pbjs.que.push(function() {
        apntag.anq.push(function() {
          pbjs.setTargetingForAst();
          apntag.loadTags();
          resolve(true);
        });
      });
    });
  }


  function configAdserver(sizes, pbjs, apntag, placementGroupId) {
    return new Promise(function(resolve, reject) {
      var PREBID_TIMEOUT = 2500;
      var adUnits = [
        {
        mediaTypes: {
          banner: {
            sizes: sizes
          }
        },
        bids: [{
          bidder: 'appnexus',
          params: {
            placementId: placementGroupId
          }
        }]
      }
      ];
      pbjs.que.push(function() {
        pbjs.addAdUnits(adUnits);
        pbjs.requestBids({
          bidsBackHandler: async function(bidResponses) {
            await initAdserver(pbjs, apntag);
            pbjs.requestSent = true;
            resolve(true);
          },
          timeout: PREBID_TIMEOUT,
        });
      });
    });
  }

  function defineTags(sizes, elementId, placementTagId) {
    return new Promise(function(resolve, reject) {
      apntag.anq.push(function() {
        apntag.defineTag({
          tagId: placementTagId,
          sizes: sizes,
          targetId: elementId,
        });
        resolve(true);
      });
    });
  }

  function setupPrebid(sizes, pbjs, apntag, elementId, placementTagId, placementGroupId) {
    return new Promise(async function(resolve, reject) {
      if (!pbjs.requestsent) {
        await configAdserver(sizes, pbjs, apntag, placementTagId, placementGroupId);
      }
      await defineTags(sizes, elementId, placementTagId);
      resolve(true);
    });
  }

  async function showTags(sizes, pbjs, apntag, htmlId, placementTagId, placementGroupId) {
    await setupPrebid(sizes, pbjs, apntag, htmlId, placementTagId, placementGroupId);
    apntag.anq.push(function() {
      apntag.showTag(htmlId);
    });
  }

  return {showTags: showTags}

})(jQuery);
