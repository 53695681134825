
Fastweb.Widgets.LoginOrSignUp = (function($) {

  function formattedDate() {
    const date = new Date();

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const min = date.getMinutes();
    const sec = date.getSeconds();
    const str = year + "/" + month + "/" + day + "-" +  hour + ":" + min + ":" + sec;

    return str;
  };

  function setBgColor(img_selector) {
    $('#sign_up_in_widget_container').css('background', $(img_selector).attr('data-bg-color'));
  }

  function fireImpressionPixel(animation, jumpedToEnd) {
    let viewedPromo = {sign_in_image_container: false, sign_up_image_container: false};
    const imageContainer = $(animation.elem).attr('id');
    const pixelTag = $(animation.elem).attr('data-impression-pixel');
    if(pixelTag && viewedPromo[imageContainer] == false) {
      $('body').append(pixelTag);
      viewedPromo[imageContainer] = true;
    }
  }

  setLoginSignupTabByPage = function(referredFromEmail = null) {
    const signInImageContainer = $('#sign_in_image_container');
    const signUpImageContainer = $('#sign_up_image_container');
    const showLoginTab = referredFromEmail || window.location.pathname == '/login' || window.location.pathname == '/logout' || window.location.pathname == '/sessions';
    if (showLoginTab) {
      $('#login_sign_up').foundation('selectTab', $('#login_panel'), false);
      signUpImageContainer.hide();
      signInImageContainer.show();
    } else {
      $('#login_sign_up').foundation('selectTab', $('#sign_up_panel'), false);
      signInImageContainer.hide();
      signUpImageContainer.show();
    }
  }

  init = function() {
    $('#login_sign_up').on('change.zf.tabs', function(event, tab, tab_content) {
      const signInImageContainer = $('#sign_in_image_container');
      const signUpImageContainer = $('#sign_up_image_container');
      const sign_in_image_present = signInImageContainer.length > 0;
      const sign_up_image_present = signUpImageContainer.length > 0;
      if(tab.attr('id') == 'sign_up_tab') {
        if (sign_in_image_present) {
          signInImageContainer.hide();
        }
        if (sign_up_image_present) {
          signUpImageContainer.show({duration: 0, done: fireImpressionPixel});
          setBgColor('#' + signUpImageContainer.find('img').attr('id'));
        }
      } else if(tab.attr('id') == 'login_tab') {
        if (sign_up_image_present) {
          signUpImageContainer.hide();
        }
        if (sign_in_image_present) {
          signInImageContainer.show({duration: 0, done: fireImpressionPixel});
          setBgColor('#' + signInImageContainer.find('img').attr('id'));
        }
      }

      if ($(tab_content).find('span.hide').length > 0 && $(tab_content).find('span.hide').children().length < 1) {
        imp_pixel = $(tab_content).find('span.hide').attr('data-impression-pixel');
        $(tab_content).find('span.hide').append(imp_pixel);
      }
    });

    $('.login_signup_button').on('click', function(e) {
      const source = $(this).data('login-source');
      const userEmail = $('#login_email').val()
      if (userEmail && userEmail.length > 0) {
        gtag('event', 'login', {'method': source, 'timestamp': formattedDate()});
      }
      return true;
    });
  }

  return {
    init: init,
    setLoginSignupTabByPage: setLoginSignupTabByPage
  };

})(jQuery);
