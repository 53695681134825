
Fastweb.UserLocation = (function($) {

  validateUserLocation = function(form_id) {

    if( $('#user_city').val() != "" && $("#user_state").val() != "" && $("#user_zip_code").val() != "" ) {
      url = document.location.origin + "/validate/validate_user_location";
      $.ajax({
        url: url,
        dataType: "json",
        data: {city: $('#user_city').val(), state: $("#user_state").val(), zip_code: $("#user_zip_code").val()},
        cache: false
      })
      .done(function() {
        $("#" + form_id).submit();
      })
      .fail(function(data) {
        var userConfirm = confirm("We could not validate the address you entered which may effect your scholarship match results. Click cancel to edit your address or OK to continue registration");
        if( userConfirm == true ) {
          $("#" + form_id).submit();
        }
      });
    }

  }

  return {validateUserLocation: validateUserLocation};

})(jQuery);
