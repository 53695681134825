Fastweb.LandingPages = (function($) {

  trackNcsaCampaignClick = function() {
    $('#track-ncsa-campaign-click').on('click', function() {
      const trackingUrl = 'https://www.fastweb.com/tracking/track_campaign_click_for_landing_pages';
      const fwUrlArr = document.URL.split('/');
      const landingPage = fwUrlArr[fwUrlArr.length - 1].split('?')[0];
      
      $.get(`${trackingUrl}?lp=${landingPage}`).always(function() { return true; });
    });
  }

  searchLearningSubjects = function() {
    $('.search_textfield').keyup(function() {
      text = $(this).val();
      $('ul.olr-list-ul').children(":not(:containsi('" + text + "'))").addClass('hide');
      $('ul.olr-list-ul').children(":containsi('" + text + "')").removeClass('hide');

      hideGroupedColumnsIfNoTextFound();
    });
  }

  function hideGroupedColumnsIfNoTextFound() {
    $('ul.olr-list-ul').each(function() {
      if ($(this).children('li.olr-list-li.hide').length === $(this).children().length) {
        $(this).parent().addClass('hide');
      } else {
        $(this).parent().removeClass('hide');
      }
    })
  }

  return {
    trackNcsaCampaignClick: trackNcsaCampaignClick,
    searchLearningSubjects: searchLearningSubjects
  }

})(jQuery);
