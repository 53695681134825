
Fastweb.WalkThrough = (function($) {
  startWalkthrough = (function() {
  // $(function() {
    //close walkthrough
    var ctc = 1;
    $( ".exit,.walkthrough_screen,.ol" ).on( "click", function() {
       if(ctc == 1){
        $('.walkthrough_screen').fadeOut("normal");
        $('body').removeClass('walkthrough');
        $('body').removeClass('walk_step_2');
        $('body').removeClass('walk_step_3');
        $('body').removeClass('walk_step_4');
        $('body').removeClass('walk_step_5');
        $('body').removeClass('walk_step_6');
        $( ".filtering-options-highlight" ).removeClass( "walk_move walk_5_move" );
        $( ".scholarship_wrap .more_to" ).first().removeClass( "walk_move walk_4_move" );
        $( ".scholarship_wrap_small .more_to" ).first().removeClass( "walk_move walk_4_move" );
        $( ".scholarship_wrap .applied_to" ).first().removeClass( "walk_move walk_3_move" );
        $( ".scholarship_wrap_small .applied_to" ).first().removeClass( "walk_move walk_3_move" );
        $( ".scholarship_wrap .favorite_to" ).first().removeClass( "walk_move walk_2_move" );
        $( ".scholarship_wrap_small .favorite_to" ).first().removeClass( "walk_move walk_2_move" );
        ctc = 0;
        document.cookie = 'scholarship_walkthrough = false; path=/'
      }
    });
    $( ".blue_bubble" ).on( "click", function() {
      event.stopPropagation();
    });
    //open WalkThrough
    $( ".walkthrough_tour" ).on( "click", function() {
      // reset the cookie and initiate the walkthrough
    });
    //walkthrough step 1
    $(".walk_1").prependTo(".matches-tabs-wrapper");
    var ctn = 1;
    $( ".next_0" ).on( "click", function() {
       if(ctn == 1){
        $('body').addClass('walkthrough walk_step_2');
        $('.walk_0 .blue_bubble').fadeOut("normal");
        $('.walk_1 .blue_bubble').fadeIn("normal");
        $('html, body').animate({
          scrollTop: $(".walk_1 .blue_bubble").offset().top - 16
        }, 50);
        ctn = 0;
      }
    });
    //walkthrough step 2
    var ctn2 = 1;
    $( ".next_1" ).on( "click", function() {
       if(ctn2 == 1){
        $('body').addClass('walk_step_3');
        $('body').removeClass('walk_step_2');
        $('.walk_1 .blue_bubble').fadeOut("normal");
        $('.walk_2 .blue_bubble').fadeIn("normal");
        $( ".scholarship_wrap .favorite_to" ).first().addClass( "walk_move walk_2_move" );
        $( ".scholarship_wrap_small .favorite_to" ).first().addClass( "walk_move walk_2_move" );
        $(".walk_2").prependTo(".walk_2_move");
        if ($(window).width() < 624) {
          // alert($(window).width());
          $('html, body').animate({
            scrollTop: $(".scholarship_wrap_small .walk_2 .blue_bubble").offset().top - 16
          }, 50);
        }
        else {
          $('html, body').animate({
            scrollTop: $(".walk_2 .blue_bubble").offset().top - 16
          }, 50);
        }
        ctn2 = 0;
      }
    });
    //walkthrough step 3
    var ctn3 = 1;
    $( ".next_2" ).on( "click", function() {
       if(ctn3 == 1){
        $('body').addClass('walk_step_4');
        $('body').removeClass('walk_step_3');
        $('.walk_2 .blue_bubble').fadeOut("normal");
        $('.walk_3 .blue_bubble').fadeIn("normal");
        $( ".scholarship_wrap .applied_to" ).first().addClass( "walk_move walk_3_move" );
        $( ".scholarship_wrap_small .applied_to" ).first().addClass( "walk_move walk_3_move" );
        $(".walk_3").prependTo(".walk_3_move");
        if ($(window).width() < 624) {
        // if ($(window).width() < 580) {
          // alert($(window).width());
          $('html, body').animate({
            scrollTop: $(".scholarship_wrap_small .walk_3 .blue_bubble").offset().top - 16
          }, 50);
        }
        else {
          $('html, body').animate({
            scrollTop: $(".walk_3 .blue_bubble").offset().top - 16
          }, 50);
        }
        ctn3 = 0;
      }
    });
    //walkthrough step 4
    var ctn4 = 1;
    $( ".next_3" ).on( "click", function() {
       if(ctn4 == 1){
        $('body').addClass('walk_step_5');
        $('body').removeClass('walk_step_4');
        $('.walk_3 .blue_bubble').fadeOut("normal");
        $('.walk_4 .blue_bubble').fadeIn("normal");
        $( ".scholarship_wrap .more_to" ).first().addClass( "walk_move walk_4_move" );
        $( ".scholarship_wrap_small .more_to" ).first().addClass( "walk_move walk_4_move" );
        $(".walk_4").prependTo(".walk_4_move");
        if ($(window).width() < 624) {
          // alert($(window).width());
          // alert('Less than 960');
          $('html, body').animate({
            scrollTop: $(".scholarship_wrap_small .walk_4 .blue_bubble").offset().top - 16
          }, 50);
        }
        else {
          $('html, body').animate({
            scrollTop: $(".walk_4 .blue_bubble").offset().top - 16
          }, 50);
        }
        ctn4 = 0;
      }
    });
    //walkthrough step 5
    var ctn5 = 1;
    $( ".next_4" ).on( "click", function() {
       if(ctn5 == 1){
        $('body').addClass('walk_step_6');
        $('body').removeClass('walk_step_5');
        $('.walk_4 .blue_bubble').fadeOut("normal");
        $('.walk_5 .blue_bubble').fadeIn("normal");
        $( ".filtering-options-highlight" ).addClass( "walk_move walk_5_move" );
        $(".walk_5").prependTo(".walk_5_move");
        $('html, body').animate({
          scrollTop: $(".walk_5 .blue_bubble").offset().top - 16
        }, 50);
        ctn5 = 0;
      }
    });
    //walkthrough cleanup
    var ctn6 = 1;
    $( ".next_5" ).on( "click", function() {
       if(ctn6 == 1){
        $('html, body').animate({
          scrollTop: $(".matches-tabs-wrapper").offset().top - 66
        }, 50);
        ctn6 = 0;
      }
    });

  });

  return {startWalkthrough: startWalkthrough}

})(jQuery);
